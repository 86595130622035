import React, {useEffect, useState} from "react";
import {Button, Modal, Table} from "react-bootstrap";
import moment from "moment";
import {hours, minutes} from "../../data/time";

const {Body, Header, Title} = Modal;

const VersionsList = ({show, hide, allVers, switchVersion, updateDPT, redux}) => {
    // Initialize state with an empty array
    const [thresholds, setThresholds] = useState([]);
    // Function to convert the date-time string to time
    const convertToTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        // Extract hours, minutes, and seconds
        let hours = String(date.getUTCHours()).padStart(2, '0');
        let minutes = String(date.getUTCMinutes()).padStart(2, '0');
        let seconds = String(date.getUTCSeconds()).padStart(2, '0');

        // Format the time as HH:MM:SS
        return `${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        setThresholds(allVers.map((v) => {
            const [hours, minutes, seconds] = convertToTime(v.maxDataProcTime).split(":");
            return {vId: v.Id, hours: hours, minutes: minutes}
        }));
    }, []);

    // Handler for minutes input change
    const handleMinutesChange = (event, vId) => {
        const minutes = event.target.value;
        setThresholds(prevState =>
            prevState.map((threshold) =>
                vId === threshold.vId ? {...threshold, minutes: minutes} : threshold
            )
        );
    };

    // Handler for hours input change
    const handleHoursChange = (event, vId) => {
        let minutes = getThresholdMinutes(vId);
        const hours = event.target.value;
        if (hours === "12") {
            minutes = "00";
        }
        setThresholds(prevState =>
            prevState.map((threshold) => {
                    return threshold.vId === vId ? {...threshold, hours: hours, minutes: minutes} : threshold
                }
            )
        );
    };

    const getThresholdHours = (vId) => {
        return thresholds.find((threshold) => threshold.vId === vId)?.hours;
    }

    const getThresholdMinutes = (vId) => {
        return thresholds.find((threshold) => threshold.vId === vId)?.minutes;
    }

    return (
        <div>
            <Modal
                show={show}
                onHide={hide}
                backdrop="static"
                keyboard={false}
                restoreFocus={false}
                dialogClassName="show-recipe-versions"
            >
                <Header closeButton>
                    <Title style={{fontWeight: "bold"}}>Versions</Title>
                </Header>
                <Body style={{maxHeight: "80vh", overflowY: "auto"}}>
                    <div style={{padding: 20}}>
                        <Table striped bordered condensed hover style={{padding: 40}}>
                            <thead>
                            <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Version</th>
                                <th>Created Date</th>
                                <th>Last Modified</th>
                                <th>Last Modified By</th>
                                <th>Comment</th>
                                <th>Recipe Duration Notification</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {allVers.map((v, i) => {
                                let username;
                                const user =
                                    redux &&
                                    redux.project &&
                                    redux.project.permissions &&
                                    redux.project.permissions.find(
                                        (u) => u.identityId === v.LastModifiedBy
                                    );
                                if (user && user.Identity && user.Identity.username) {
                                    username = user.Identity.username;
                                }
                                return (
                                    <tr key={i}>
                                        <td>{v.Id}</td>
                                        <td>{v.Name}</td>
                                        <td>{v.Version}</td>
                                        <td>{moment(v.CreatedDate).format("LLLL")}</td>
                                        <td>{moment(v.LastModified).format("LLLL")}</td>
                                        <td>{username}</td>
                                        <td>{v.comment}</td>
                                        <td>
                                            <div style={{
                                                display: "flex",
                                                gap: 20,
                                                margin: "5px",
                                                alignContent: "center"
                                            }}>
                                                <span>Hours:</span>
                                                <select
                                                    name="thresholdHours"
                                                    id="hours"
                                                    placeholder="hours"
                                                    value={getThresholdHours(v.Id)}
                                                    onChange={(e) => handleHoursChange(e, v.Id)}
                                                    className="form-control"
                                                    style={{width: 100}}
                                                >
                                                    {hours.map((value, j) => {
                                                        return (
                                                            <option value={value} key={j}>
                                                                {value}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                <span>Minutes:</span>
                                                <select
                                                    name="thresholdMinutes"
                                                    id="minutes"
                                                    placeholder="minutes"
                                                    value={getThresholdMinutes(v.Id)}
                                                    disabled={getThresholdHours(v.Id) === "12"}
                                                    onChange={(e) => handleMinutesChange(e, v.Id)}
                                                    className="form-control"
                                                    style={{width: 100}}>
                                                    {minutes.map((value, j) => {
                                                        return (
                                                            <option value={value} key={j}>
                                                                {value}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                <Button
                                                    bsSize="small"
                                                    bsStyle="primary"
                                                    style={{marginLeft: "25px"}}
                                                    onClick={() => updateDPT(v.Id, {
                                                        thresholdHours: getThresholdHours(v.Id),
                                                        thresholdMinutes: getThresholdMinutes(v.Id)
                                                    })}>
                                                    Update
                                                </Button>
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{
                                                display: "flex",
                                                gap: 20,
                                                margin: "5px",
                                                alignContent: "center"
                                            }}>
                                                <Button
                                                    bsSize="small"
                                                    bsStyle="primary"
                                                    style={{marginLeft: "25px"}}
                                                    onClick={() => switchVersion(v.Version)}>
                                                    Select
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </div>
                </Body>
            </Modal>
        </div>
    );
};

export default VersionsList;
